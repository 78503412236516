/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-10-13",
    versionChannel: "nightly",
    buildDate: "2023-10-13T00:24:41.221Z",
    commitHash: "ff2814c84f1bf69353ca5107298c134b00b16ec9",
};
